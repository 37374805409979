import React from "react"
import { useStaticQuery, Link } from "gatsby"

import { useProjectTypes } from "../hooks/project-types"

const HeaderProjects = () => {
  const data = useProjectTypes()
  
  return (
    <div className="header-nav">
      <ul>
        <li><Link to="/projekte">Chronologisch</Link></li>
        { data.map((data, index) => (
          <li key={index}><Link to={`/projekte/${data.node.slug}`}>{data.node.title}</Link></li>
        ))}  
      </ul>
    </div>
  )
}

export default HeaderProjects