import { useStaticQuery, graphql } from "gatsby"

export const useProjectTypes = () => {
  const { allStrapiProjectType } = useStaticQuery(
  graphql`
    query {
      allStrapiProjectType {
        edges {
          node {
            slug
            title
            picture {
              localFile {
                childImageSharp {
                  fluid {
                  ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  `
  )

  return allStrapiProjectType.edges
}