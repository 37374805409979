import React from "react"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"
import HeaderProjekte from "../components/header_projekte"

const ProjektePage = ({ data }) => {
  const headerImg = data.allStrapiChronologisch.edges[0].node.picture.localFile.childImageSharp.fluid;
  const projects = data.allStrapiProject.edges;

  return (
    <Layout>
      <SEO title="Projekte" />
      <HeaderProjekte />
      <Img fluid={ headerImg } className="heroimage"/>

      <div className="grid">
          { projects.map(({ node }, index) => (
            <div key={index}>
              <Link to={`/projekt/${node.slug}`}><Img fluid={ node.pictures[0].localFile.childImageSharp.fluid  } className="heroimage-projekte" />
              <div className="grid-desc-left">{ node.title }</div><div className="grid-desc-right">{ node.date }</div></Link>
            </div>
          ))}   
      </div>

    </Layout>
  )
}

export default ProjektePage

export const query = graphql`
  query MyQuery {
    allStrapiChronologisch {
      edges {
        node {
          picture {
            localFile {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
    allStrapiProject(
      sort: {order: DESC, fields: date}
    ) {
      edges {
        node {
          slug
          title
          date
          pictures {
            localFile {
              childImageSharp {
                fluid(maxHeight: 150) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
